import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';



// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAmCbtWX6yMmEuiWihUKGztaem0P52WInU",
    authDomain: "ak-pools.firebaseapp.com",
    projectId: "ak-pools",
    storageBucket: "ak-pools.appspot.com",
    messagingSenderId: "734835378088",
    appId: "1:734835378088:web:0acbce7efb8aeed9c0bf9d",
    measurementId: "G-Q064YWL2BR"
  };

    firebase.initializeApp(firebaseConfig);
    
    export default firebase;