import React, {useState, useRef} from 'react'
import emailjs from '@emailjs/browser'
import './freeEstimate.css'


const FreeEstimate =() => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [fax, setFax] = useState('');
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  const [projectCity, setProjectCity] = useState('');
  const [projectState, setProjectState] = useState('');
  const [projectType, setProjectType] = useState('');
  const [projectTimeFrame, setProjectTimeFrame] = useState('');
  const [comments, setComments] = useState('')
  const [message, setMessage] = useState('');

  const [divingWall, setDivingWall] = useState('');
  const [divingBoard, setDivingBoard] = useState('');
  const [divingPlatform, setDivingPlatform] = useState('');
  const [deckJets, setDeckJets] = useState('');
  const [laminars, setLaminars] = useState('');
  const [waterfalls, setWaterfalls] = useState('');
  const [waterfeatures, setWaterfeatures] = useState('');
  const [ropeSwing, setRopeSwing] = useState('');
  const [rockFeatures, setRockFeatures] = useState('');
  const [slide, setSlide] = useState('');
  const [deepend, setDeepend] = useState('');

  const [concreteDecking, setConcreteDecking] = useState('');
  const [powerDecking, setPowerDecking] = useState('');
  const [retainingWall, setRetainingWall] = useState('');
  const [sittingWall, setSittingWall] = useState('');
  const [fireplace, setFireplace] = useState('');
  const [outdoorKitchen, setOutdoorKitchen] = useState('');
  const [poolHouse, setPoolHouse] = useState('');
  const [pavilion, setPavilion] = useState('');
  const [pergola, setPergola] = useState('');
  const [outdoorLighting, setOutdoorLighting] = useState('');
  const [outdoorStereo, setOutdoorStereo] = useState('');



  const form = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs.sendForm('service_etksomr', 'template_h5gm2fd', form.current, '9ayzJCmS8RJ1LTfxs')
      .then((result) => {
          console.log(result.text);
          setMessage('Your message has been sent. Thank you!');
      }, (error) => {
          console.log(error.text);
      });
  };


  return (
    <div>
      <div className='freeHero'>
        <div className='freeHeroTitle'>
          <h2>Free Estimate Quick</h2>
          <p>Complete the form below to email AK Pools and receive prompt contact and additional information</p>
        </div>
        <img className='heroImage' src={require('../assets/poolTwilight.jpg')} alt='filler'/> 
      </div>
      <div className='form-container' >  
        <h2 className="personal-info-heading">Personal Info</h2>
        <form ref={form} onSubmit={handleSubmit} className="personal-info-form">
          <div className="form-group-row">
          <div style={{display: 'flex'}}>
            <div>
              <label htmlFor="firstName" className="form-label">First Name:*</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className="form-control"
               />
            </div>
            <div>
              <label htmlFor="lastName" className="form-label">Last Name:*</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                className="form-control"
              />
            </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email" className="form-label">Email:*</label>
            <input
             type="email"
             id="email"  
             name="email"
             value={email}
             onChange={(e) => setEmail(e.target.value)}
             required
             className="form-control"
            />
            </div>
            <div className="form-group-row">
            <div style={{display: 'flex'}}>
              <div>
                <label htmlFor="phone" className="form-label">Phone:*</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="form-control"
                  required
            />
            </div>
            <div>
              <label htmlFor="fax" className="form-label">Fax:</label>
              <input
                type="tel"
                id="fax"
                name="fax"
                value={fax}
                onChange={(e) => setFax(e.target.value)}
                className="form-control"
              />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="company" className="form-label">Company:</label>
            <input
              type="text"
              id="company"
              name="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="position" className="form-label">Position:</label>
            <select
              id="position"
              name="position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              className="form-control"
            >
              <option value='placeHolder'>Select</option>
              <option value="architect">Architect</option>
              <option value="homeowner">Homeowner</option>
              <option value="designer">Designer</option>
              <option value="builder">Builder</option>
              <option value="engineer">Engineer</option>
              <option value="other">Other</option>
              {/* Add more options as needed */}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="address" className="form-label">Address:</label>
            <input
              id="address"
              name="address"
              rows="3"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="form-control"
            ></input>
          </div>
          <div className="form-group">
            <label htmlFor="city" className="form-label">City:</label>
            <input
              type="text"
              id="city"
              name="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="state" className="form-label">State:</label>
            <input
              type="text"
              id="state"
              name="state"
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="form-control"
            />
          </div>
    
          <div>
            <h2 className="personal-info-heading">Project Details</h2>
              <div className="form-group">
                <label htmlFor="projectCity" className="form-label">Project City:</label>
                <input
                  type="text"
                  id="projectCity"
                  name="projectCity"
                  value={projectCity}
                  onChange={(e) => setProjectCity(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="projectState" className="form-label">Project State:</label>
                <input
                  type="text"
                  id="projectState"
                  name="projectState"
                  value={projectState}
                  onChange={(e) => setProjectState(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="projectType" className="form-label">Project Type:</label>
                <select
                  id="projectType"
                  name="projectType"
                  value={projectType}
                  onChange={(e) => setProjectType(e.target.value)}
                  className="form-control"
                >
                  <option value="Gunite Pool">Gunite Pool</option>
                  <option value="Gunite Spa">Gunite Spa</option>
                  <option value="V Shaped Sports Pool">V Shaped Sports Pool</option>
                  <option value="Wedge Shaped Pool">Wedge Shaped Pool</option> 
                  <option value="Raised Spa">Raised Spa</option>
                </select>
              </div>
              <div className="form-group">
          <label className="form-label">Pool Add Ons:</label>
          <div className='check-box-main' style={{display: 'flex'}}>
          <div className='check-box-container'>
            <div>
              <input
               type="checkbox"
               id="divingWall"
               name="divingWall"
               value={divingWall}
               checked={divingWall  === '' ? false : true}
               onChange={(e) => divingWall === '' ? setDivingWall('Diving Wall,') : setDivingWall('')}
               className="form-checkbox"
              />
              <label htmlFor="diving wall" className="checkbox-label">Diving Wall</label>
            </div>
            <div>
              <input
               type="checkbox"
               id="divingPlatform"
               name="divingPlatform"
               value={divingPlatform}
               checked={divingPlatform === '' ? false : true}
               onChange={(e) => divingPlatform === '' ? setDivingPlatform('Diving Platform,') : setDivingPlatform('')}
               className="form-checkbox"
            />
            <label htmlFor="diving platform" className="checkbox-label">Diving Platform</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="divingBoard"
              name="divingBoard"
              value={divingBoard}
              checked={divingBoard === '' ? false : true}
              onChange={(e) => divingBoard === '' ? setDivingBoard('Diving Board,') : setDivingBoard('')}
              className="form-checkbox"
            />
            <label htmlFor="diving board" className="checkbox-label">Diving Board</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="slide"
              name="slide"
              value={slide}
              checked={slide === '' ? false : true}
              onChange={(e) => slide === '' ? setSlide('Slide,') : setSlide('')}
              className="form-checkbox"
            />
            <label htmlFor="slide" className="checkbox-label">Slide</label>
           </div>
          </div>
        <div className="form-group">
          <div className='check-box-container'>
           <div>
             <input
               type="checkbox"
               id="deckJets"
               name="deckJets"
               value={deckJets}
               checked={deckJets === '' ? false : true}
               onChange={(e) => deckJets === '' ? setDeckJets('Deck Jets,') : setDeckJets('')}
               className="form-checkbox"
             />
             <label htmlFor="deck jets" className="checkbox-label">Deck Jets</label>
            </div>
            <div>
              <input
               type="checkbox"
               id="laminars"
               name="laminars"
               value={laminars}
               checked={laminars === '' ? false : true}
               onChange={(e) => laminars === '' ? setLaminars('Laminars,') : setLaminars('')}
               className="form-checkbox"
              />
              <label htmlFor="laminars" className="checkbox-label">Laminars</label>
            </div>
            <div>
              <input
               type="checkbox"
               id="waterfalls"
               name="waterfalls"
               value={waterfalls}
               checked={waterfalls === '' ? false : true}
               onChange={(e) => waterfalls === '' ? setWaterfalls('Waterfalls,') : setWaterfalls('')}
               className="form-checkbox"
              />
              <label htmlFor="waterfalls" className="checkbox-label">WaterFalls</label>
            </div>
            <div>
              <input
               type="checkbox"
               id="waterFeatures"
               name="waterFeatures"
               value={waterfeatures}
               checked={waterfeatures === '' ? false : true}
               onChange={(e) => waterfeatures === '' ? setWaterfeatures('Water Features,') : setWaterfeatures('')}
               className="form-checkbox"
              />
              <label htmlFor="waterfeatures" className="checkbox-label">Water Features</label>
            </div>
          </div>
        </div>
        <div className="form-group">
        <div className='check-box-container'>
            <div>
              <input
               type="checkbox"
               id="ropeSwing"
               name="ropeSwing"
               value={ropeSwing}
               checked={ropeSwing === '' ? false : true}
               onChange={(e) => ropeSwing === '' ? setRopeSwing('Rope Swing,') : setRopeSwing('')}
               className="form-checkbox"
              />
              <label htmlFor="urgent" className="checkbox-label">Rope Swing</label>
            </div>
            <div>         
              <input
               type="checkbox"
               id="rockFeatures"
               name="rockFeatures"
               value={rockFeatures}
               checked={rockFeatures === '' ? false : true}
               onChange={(e) => rockFeatures === '' ? setRockFeatures('Rock Features,') : setRockFeatures('')}
               className="form-checkbox"
              />
              <label htmlFor="rock features" className="checkbox-label">Rock Features</label>
            </div>
            <div>
              <input
                type="checkbox"
                id="deepend"
                name="deepend"
                value={deepend}
                checked={deepend === '' ? false : true}
                onChange={(e) => deepend === '' ? setDeepend('Deepend,') : setDeepend('')}
                className="form-checkbox"
              />
              <label htmlFor="deepend" className="checkbox-label">Deepend</label>
             </div>
            </div>
            </div>
          </div>        
        </div>
        <div className="form-group">
                <label htmlFor="projectTimeFrame" className="form-label">Project Time Frame:</label>
                <select
                  id="projectTimeFrame"
                  name="projectTimeFrame"
                  value={projectTimeFrame}
                  onChange={(e) => setProjectTimeFrame(e.target.value)}
                  className="form-control"
                >
                  <option value="Now">Now</option>
                  <option value="1-2 Months">1-2 Months</option>
                  <option value="3-6 Months">3-6 Months</option>
                  <option value="6+ Months">6+ Months</option>
                </select>
              </div>
              <div className="form-group">
          <label className="form-label">Other Backyard Projects:</label>
          <div className='check-box-main' style={{display: 'flex'}}>
            <div className='check-box-container'>
            <div>
          <input
            type="checkbox"
            id="poolHouse"
            name="poolHouse"
            value={poolHouse}
            checked={poolHouse === '' ? false : true}
            onChange={(e) => poolHouse === '' ? setPoolHouse('Pool House,') : setPoolHouse('')}
            className="form-checkbox"
          />
          <label htmlFor="poolHouse" className="checkbox-label">Pool House</label>
          </div>
              <div>
               <input
                type="checkbox"
                id="concreteDecking"
                name="concreteDecking"
                value={concreteDecking}
                checked={concreteDecking === '' ? false : true}
                onChange={(e) => concreteDecking === '' ? setConcreteDecking('Concrete Decking,') : setConcreteDecking('')}
                className="form-checkbox"
               />
              <label htmlFor="concrete decking" className="checkbox-label">Concrete Decking</label>
          </div>
          <div>
          <input
            type="checkbox"
            id="powerDecking"
            name="powerDecking"
            value={powerDecking}
            checked={powerDecking === '' ? false : true}
            onChange={(e) => powerDecking === '' ? setPowerDecking('Power Decking,') : setPowerDecking('')}
            className="form-checkbox"
          />
          <label htmlFor="power decking" className="checkbox-label">Power Decking</label>
          </div>
          <div>
          <input
            type="checkbox"
            id="retainingWall"
            name="retainingWall"
            value={retainingWall}
            checked={retainingWall === '' ? false : true}
            onChange={(e) => retainingWall === '' ? setRetainingWall('Retaining Wall,') : setRetainingWall('')}
            className="form-checkbox"
          />
          <label htmlFor="retaining wall" className="checkbox-label">Retaining Wall</label>
          </div>
        </div>
        <div className="form-group">
        <div className='check-box-container'>
          <div>
        <input
            type="checkbox"
            id="sittingWall"
            name="sittingWall"
            value={sittingWall}
            checked={sittingWall === '' ? false : true}
            onChange={(e) => sittingWall === '' ? setSittingWall('Sitting Wall,') : setSittingWall('')}
            className="form-checkbox"
          />
          <label htmlFor="sitting wall" className="checkbox-label">Sitting Wall</label>
          </div>
          <div>
          <input
            type="checkbox"
            id="fireplace"
            name="fireplace"
            value={fireplace}
            checked={fireplace === '' ? false : true}
            onChange={(e) => fireplace === '' ? setFireplace('Fireplace,') : setFireplace('')}
            className="form-checkbox"
          />
          <label htmlFor="fireplace" className="checkbox-label">Fireplace</label>
          </div>
          <div>
          <input
            type="checkbox"
            id="outdoorKitchen"
            name="outdoorKitchen"
            value={outdoorKitchen}
            checked={outdoorKitchen === '' ? false : true}
            onChange={(e) => outdoorKitchen === '' ? setOutdoorKitchen('Outdoor Kitchen,') : setOutdoorKitchen('')}
            className="form-checkbox"
          />
          <label htmlFor="outdoorKitchen" className="checkbox-label">Outdoor Kitchen</label>
          </div>
          <div>
          <input
            type="checkbox"
            id="pavilion"
            name="pavilion"
            value={pavilion}
            checked={pavilion === '' ? false : true}
            onChange={(e) => pavilion === '' ? setPavilion('Pavilion,') : setPavilion('')}
            className="form-checkbox"
          />
          <label htmlFor="pavilion" className="checkbox-label">Pavilion</label>
          </div>
          </div>
        </div>
        <div className="form-group">
        <div className='check-box-container'>
          <div>
          <input
            type="checkbox"
            id="pergola"
            name="pergola"
            value={pergola}
            checked={pergola === '' ? false : true}
            onChange={(e) => pergola === '' ? setPergola('Pergola,') : setPergola('')}
            className="form-checkbox"
          />
          <label htmlFor="pergola" className="checkbox-label">Pergola</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="outdoorLighting"
            name="outdoorLighting"
            value={outdoorLighting}
            checked={outdoorLighting === '' ? false : true}
            onChange={(e) => outdoorLighting === '' ? setOutdoorLighting('Outdoor Lighting,') : setOutdoorLighting('')}
            className="form-checkbox"
          />
          <label htmlFor="outdoorLighting" className="checkbox-label">Outdoor Lighting</label>
          </div>
          <div>
          <input
            type="checkbox"
            id="outdoorStereo"
            name="outdoorStereo"
            value={outdoorStereo}
            checked={outdoorStereo === '' ? false : true}
            onChange={(e) => outdoorStereo === '' ? setOutdoorStereo('Outdoor Stereo,') : setOutdoorStereo('')}
            className="form-checkbox"
          />
          <label htmlFor="outdoorStereo" className="checkbox-label">Outdoor Stereo</label>
          </div>
        </div>
        </div>
        </div>
      </div>  
              <div className="form-group">
                <label htmlFor="comments" className="form-label">Comments:</label>
                <textarea
                  id="comments"
                  name="comments"
                  rows="3"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  className="form-control"
                ></textarea>
              </div>
              <div>
                <p className="form-label">{message}</p>
              </div>
              <div className="form-group">
                <input type="submit" value="Submit" className="submit-button" />
              </div>
            </div>
          </form>
        </div>
  </div>
);

}

export default FreeEstimate