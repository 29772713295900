import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages/Home'
import FreeEstimate from './pages/freeEstimate'
import About from './pages/about';
import Gallery from './pages/gallery';
import CornerGuide from './pages/guide';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/free-estimate" element={<FreeEstimate />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
      </Router>
      <CornerGuide />
    </div>
  );
}

export default App;
