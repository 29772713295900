import React, { useEffect, useState } from 'react';
import firebase from '../firebase';
import './Home.css';

function Home() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);

    const data = await firebase.firestore().collection('data').doc('homePage').get();
    setData(data.data());

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    loading ? (
      <div>
        <h1 style={{ color: '#fff' }}>Loading...</h1>
      </div>
    ) : (
      <div>
        <div className="hero-section">
          <img src={require('../assets/heroImage.jpg')} alt="Hero Section" />
          <div className="hero-overlay"></div>
          <div className="hero-content">
          <img src={require('../assets/akLogo.png')} alt="ak logo"/>
            <h3>Turning houses into dream homes, one pool at a time.</h3>
            <a href="/free-estimate">
              <p>Request Free Estimate Here</p>
            </a>
          </div>
        </div>
        <div className="transition-div"></div>

        <div className="mainHomeContainer">
          
          <div className="container">
            <div className="text-container">
              <h2>Escape The Ordinary</h2>
              <p>
                <span style={{ color: '#037689' }}>Elevate</span> your outdoor space with our sleek and modern{' '}
                <span style={{ color: '#037689' }}>backyard</span> design.
              </p>
              <p style={{ fontStyle: 'italic' }}>Click the photo to get a free quote</p>
            </div>
            <div className="image-container image-hover">
              <a href="/free-estimate">
                <img src={require('../assets/poolArk.jpg')} alt="poolside" />
              </a>
            </div>
          </div>

          <div className="freeEstimate">
            <h2>Gallery</h2>
            <p>Click any of the photos to check out a few of our projects</p>
            <div className="image-grid">
              <a href="/gallery">
                <img src={require('../assets/golfPool.jpg')} alt="Filler" />
              </a>
              <a href="/gallery">
                <img src={require('../assets/poolSpring.jpg')} alt="Filler" />
              </a>
              <a href="/gallery">
                <img src={require('../assets/poolSlide.jpg')} alt="Filler" />
              </a>
              <a href="/gallery">
                <img src={require('../assets/poolBasketball.jpg')} alt="Filler" />
              </a>
              <a href="/gallery">
                <img src={require('../assets/pooltrees.jpg')} alt="Filler" />
              </a>
              <a href="/gallery">
                <img src={require('../assets/poolSpring.jpg')} alt="Filler" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer">
          <p>Email: Kevin@akpools.net</p>
          <img src={require('../assets/akLogo.png')} alt="ak logo" />
          <p>Phone: 801-376-4222</p>
        </div>
      </div>
    )
  );
}

export default Home;
