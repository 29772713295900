import React, { useState } from 'react';
import './guide.css';

const CornerGuide = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [menuOpen , setMenuOpen] = useState(false);

  const handleClick = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div>
      <div
        className='extraDiv'
        style={{ display: isHovered ? 'block' : 'none' }}      
      ></div>
      <div
        className={`corner-guide ${isHovered ? 'hovered' : ''}`}
        onClick={handleClick}
      >
        {!isHovered ? (
          <p className="guide-text">Guide</p>
        ) : (
          <React.Fragment>
            <a href="/">Home</a>
            <a href="/free-estimate">Free Estimate</a>
            <a href="/gallery">Gallery</a>
            <a href="/about">About</a>
            <button onClick={handleClick} style={{backgroundColor:'transparent', border: 'none', color: '#fff'}}>Close</button>
          </React.Fragment>
        )}
      </div>
      <div className={menuOpen ? 'menu' : 'guideButton'}>
          { menuOpen ? 
          <div className="menuItems">
            <a href="/">Home</a>
            <a href="/free-estimate">Free Estimate</a>
            <a href="/gallery">Gallery</a>
            <a href="/about">About</a>
          </div>  : null
           }
        <button onClick={()=> setMenuOpen(!menuOpen)}>
            {menuOpen ? 'Close' : 'Guide'}        
        </button>
      </div>
    </div>
  );
};

export default CornerGuide;
