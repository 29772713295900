import React, { useState } from "react";
import './gallery.css';

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (image) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  const imageClickedStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const closeBtnStyle = {
    position: "absolute",
    top: 20,
    right: 20,
    color: "#fff",
    fontSize: "1.5rem",
    cursor: "pointer",
  };

  return (
    <div className="galleryMainDiv">
      <h1>Gallery</h1>
      <div className="container-reverse">
        <div className="text-container">
          <p style={{ marginLeft: 50, fontStyle: 'italic' }}>Experience pure luxury and relaxation with our stunning pool design</p>
        </div>
        <div className="image-container">
          <img src={require('../assets/golfPool.jpg')} alt="poolside" onClick={() => openImage(require('../assets/golfPool.jpg'))} />
        </div>
        <div className="image-container-three">
          <img src={require('../assets/poolChairs.jpg')} alt="Pool Construction" onClick={() => openImage(require('../assets/poolChairs.jpg'))} />
          <img src={require('../assets/pooltrees.jpg')} alt="Pool Construction" onClick={() => openImage(require('../assets/pooltrees.jpg'))} />
          <img src={require('../assets/poolSpring.jpg')} alt="Pool Construction" onClick={() => openImage(require('../assets/poolSpring.jpg'))} />
        </div>
      </div>
      <div className="container" style={{ marginTop: 50 }}>
        <div className="text-container">
          <p style={{ marginLeft: 50, fontStyle: 'italic' }}>Dive into a world of refreshing possibilities with our crystal-clear pool, ideal for hot summer days.</p>
        </div>
        <div className="image-container">
          <img src={require('../assets/poolSlide.jpg')} alt="poolside" onClick={() => openImage(require('../assets/poolSlide.jpg'))} />
        </div>
        <div className="image-container-three">
          <img src={require('../assets/poolChairs.jpg')} alt="Pool Construction" onClick={() => openImage(require('../assets/poolChairs.jpg'))} />
          <img src={require('../assets/poolBasketball.jpg')} alt="Pool Construction" onClick={() => openImage(require('../assets/poolBasketball.jpg'))} />
          <img src={require('../assets/poolBar.jpg')} alt="Pool Construction" onClick={() => openImage(require('../assets/poolBar.jpg'))} />
        </div>
      </div>
      <div className="container-reverse">
        <div className="text-container">
          <p style={{ marginLeft: 50, fontStyle: 'italic' }}>Elevate your outdoor space with our sleek and modern pool design</p>
        </div>
        <div className="image-container">
          <img src={require('../assets/poolFlat.jpg')} alt="poolside" onClick={() => openImage(require('../assets/poolFlat.jpg'))} />
        </div>
      </div>

      {selectedImage && (
        <div style={imageClickedStyle}>
          <img src={selectedImage} alt="full-screen" style={{ maxHeight: "90%", maxWidth: "90%" }} />
          <span style={closeBtnStyle} onClick={closeImage}>&times;</span>
        </div>
      )}
    </div>
  );
}

export default Gallery;
