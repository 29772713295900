import React, {useState} from 'react'

import './about.css';

const About = () => {
  
  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (image) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  const imageClickedStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const closeBtnStyle = {
    position: "absolute",
    top: 20,
    right: 20,
    color: "#fff",
    fontSize: "1.5rem",
    cursor: "pointer",
  };
  

  return (
    <div className="about-container">
      <h1>About AK Pools<img src={require('../assets/akLogo.png')} alt="ak logo"/></h1>
      <p>Welcome to AK Pools, your premier pool construction and renovation experts.</p>
      <div className="image-container-about">
        <img src={require('../assets/arkConst.jpg')} alt="Pool Construction" onClick={() => openImage(require('../assets/arkConst.jpg'))}/>
        <img src={require('../assets/arkConst3.jpg')} alt="Pool Construction" onClick={() => openImage(require('../assets/arkConst3.jpg'))}/>
        <img src={require('../assets/rebar.jpg')} alt="Pool Construction" onClick={() => openImage(require('../assets/rebar.jpg'))}/>
        <img src={require('../assets/poolArk.jpg')} alt="Pool Construction" onClick={() => openImage(require('../assets/poolArk.jpg'))}/>
      </div>
      <p>
        AK Pools has been serving customers for over two year, providing top-quality pool design, construction,
        and backyard services. We pride ourselves on delivering exceptional results that exceed our customers'
        expectations.
      </p>
      <p>
        Our team of experienced professionals is dedicated to turning your dream pool into a reality. Whether you
        are looking for a custom-designed pool, outdoor accessory, or regular maintenance services, we have the
        expertise to handle all your pool-related needs.
      </p>
      <p>
        At AK Pools, we understand that a pool is more than just a place to swim; it's a gathering spot for family
        and friends, a place to relax and unwind, and a valuable addition to your property. That's why we take
        great care in every step of the construction or renovation process to ensure that your pool not only looks
        stunning but also functions flawlessly.
      </p>
      <p>Contact us today to schedule a consultation and let us help you create the pool of your dreams.</p>

      {selectedImage && (
        <div style={imageClickedStyle} className='selectedImg'>
          <img src={selectedImage} alt="full-screen" style={{ maxHeight: "100%", maxWidth: "100%" }} />
          <span style={closeBtnStyle} onClick={closeImage}>&times;</span>
        </div>
      )}
    </div>
  );
};

export default About;
